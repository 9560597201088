/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


div {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

#left-side,
#right-side {
  padding: 40px;
  padding-bottom: 80px;
}

#users-title {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 10px;
}


#root {
  height: 100%;
}

#app {
  flex-direction: row;
  height: 100%;
}

#left-side {
  width: 20vw;
  height: 100%;
  border-right: solid 0.5px rgb(128, 128, 128);
}

#mode-div {
  align-self: center;
}

#user-list {
  flex: 1;
  align-items: flex-start;
}
.users {
  flex: 1;
  margin-bottom: 20px;
}

#mode-button {
  align-self: center;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 12px;
  width: 20;
  
  font-size: 14px;
  font-style: bold;
  color:rgb(228, 228, 228);
  background-color:rgb(189, 82, 82);
  cursor: pointer;
  transition: 0.4s;
}

#mode-button:hover {
  color:rgb(48, 48, 48);
  background-color: rgb(238, 147, 147);
}

#unique-list {
  margin-bottom: 10px;
}

#right-side {
  width: 80vw;
  height: 100%;
}

#my-message {
  flex-direction: row-reverse;
}

#blue-message {
  background-color: rgb(76, 159, 228);
  color: aliceblue;
}

#other-message {
  flex-direction: row;
}

.flex-change {
  flex-direction: row-reverse;
}

#timestamp-1 {
  font-size: 12px;
  padding-left: 10px;
  margin-bottom: 10px;
  border-radius: 12px;
}

#timestamp-2 {
  font-size: 12px;
  align-items: flex-end;
  padding-left: 10px;
  margin-bottom: 10px;
  border-radius: 12px;
  width: fit-content; 
}

.user-initials {
  margin-bottom: 10px;
  border-radius: 12px;
  padding: 10px;
  width: fit-content; 
}

.message-list {
  margin-bottom: 10px;
  background-color: aliceblue;
  border-radius: 12px;
  color:rgb(79, 106, 143);
  padding: 10px;
  width: fit-content;
}

#messages {
  overflow-y: auto;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

#messages::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#messages::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#draft-message, 
#mode-button {
  height: 40px;
}


#draft-message {

  flex-direction: row;
  background-color: white;
  padding: 8px;
  border-radius: 12px;
}

input {
  border: none;
  outline: none;
}

#send-button {
  border: none;
  outline: none;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.6s;
  background-color: rgb(228, 228, 228);

  font-size: 20px;
  font-style: bold;
}

#send-button:hover {
  color: aliceblue;
  background-color: rgb(124, 167, 223);
}
